
import { useCallback, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useApp } from '../../hooks/useApp';
import { useTicketSocket } from '../../hooks/useTicketSocket';
import { Event } from '../../models/Event';
import { useStore } from '../../store/Store';
import { CheckInStatus, CHECKIN_STATUS } from '../CheckInStatus';
import { QrScanner } from '../QrScanner';
import { StartCheckinButton } from '../StartCheckinButton';

interface SingleEventViewProps {
	event: Event | null,
}

const SingleEventView = (props: SingleEventViewProps) => {
	const {
		event,
	} = props;

	const autoSubmitLength = Number(process.env.REACT_APP_BARCODE_AUTOSUBMIT_LENGTH);

	const [scannerVisible, setScannerVisible] = useState(false);
	const [alreadyScanning, setAlreadyScanning] = useState(false);
	const [manualBarcode, setManualBarcode] = useState('');
	const { state } = useStore();
	const { goHome } = useApp();
	const { checkInTicket } = useTicketSocket();

	const getCheckinStatus = () => {
		if(state.loading){
			return CHECKIN_STATUS.LOADING;
		}
		if(state.error && !state.loading){
			return CHECKIN_STATUS.ERROR;
		}
		return CHECKIN_STATUS.SUCCESS;
	};

	const updateBarcodeValue = useCallback((value: string) => {
		setManualBarcode(value);
		if(event && autoSubmitLength && autoSubmitLength > 0 && value.length >= autoSubmitLength){
			setAlreadyScanning(true);
			checkInTicket(event.id, value);
		}
	}, [
		event,
		autoSubmitLength,
		setManualBarcode,
		setAlreadyScanning,
		checkInTicket,
	]);

	const renderManualBarcodeInput = useCallback(() => {
		if(alreadyScanning){
			return <></>;
		}
		return (
			<>
				<section className="check-in">
					<div className="container">
						<div className="row">
							<div className="col check-in-col">
								<InputGroup className="barcode-scanner">
									<FormControl
										className="barcode-scanner-input"
										placeholder="Scan barcode"
										aria-label="ticket barcode"
										value={manualBarcode}
										onChange={(element) => {
											updateBarcodeValue(element.currentTarget.value);
										}}
										autoFocus
									/>
								</InputGroup>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}, [
		manualBarcode,
		alreadyScanning,
		updateBarcodeValue,
	]);

	if(!event){
		goHome();
		return <></>;
	}

	// Bug here, some reason it's displaying a day before the epoch? (0 offset?, i don't think so?)
	const eventEpoch = new Date(event.unixTimeOfEvent);
	const eventMonth = eventEpoch.toLocaleString('en-us', { month: 'long' });
	const eventDay = Number(eventEpoch.toLocaleString('en-us', { day: '2-digit' }));

	const renderContent = () => {

		if(alreadyScanning){
			return (
				<CheckInStatus
					state={getCheckinStatus()}
					startAnotherScan={() => {
						setAlreadyScanning(false);
						setManualBarcode('');
					}}
				/>
			);
		}

		if(scannerVisible){
			return (
				<QrScanner
					scannedCode={(code: string) => {
						if(!alreadyScanning){
							setAlreadyScanning(true);
							checkInTicket(event.id, code);
						}
					}}
					cancelClicked={() => {
						setAlreadyScanning(false);
						setScannerVisible(false);
					}}
				/>
			);
		}

		return (
			<StartCheckinButton
				startClicked={() => {
					setScannerVisible(true);
				}}
			/>
		);
	};

	return (
		<>
			<section className="events">
				<div className="row events-list-row">
					<div className="col-12 events-list-col" onClick={goHome}>
						<span className="chevron chevron-left">
							<i className="fa fa-chevron-left"></i>
						</span>
						<div className="calendar">
							<span className="date-name">{eventMonth}</span>
							<span className="date-number">{eventDay}</span>
						</div>
						<div className="summary">
							<span className="title">{event.title}</span>
							<span className="location">{event.location}</span>
						</div>
					</div>
				</div>
			</section>
			{renderManualBarcodeInput()}
			{renderContent()}
		</>
	);
};

export { SingleEventView };