import { Event } from '../models/Event';
import { IAuthState, initalAuthState } from './Auth/IAuthState';

export interface IState {
	auth: IAuthState;
	error: string;
	loading: boolean;
	data: {
		events: Event[] | null
		selectedEvent: Event | null;
		orders: unknown[] | null;
	};
	backendConnected: boolean;
}

export const initalState: IState = {
	auth: initalAuthState,
	error: '',
	loading: false,
	data: {
		events: null,
		selectedEvent: null,
		orders: null,
	},
	backendConnected: false,
};