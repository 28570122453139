import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthHooks } from '../../hooks/useAuth';
import { useStore } from '../../store/Store';
import logo from '../../ts-logo.svg';
import { AuthForm } from '../AuthForm';

const LoginView = () => {
	const {
		logIn,
	} = useAuthHooks();

	const { pullLoginFromLocalstorage } = useAuthHooks();
	const { state: { auth: { user } } } = useStore();
	const history = useHistory();

	useEffect(() => {

		if(!user){
			if(pullLoginFromLocalstorage()){
				history.push('/');
			}
		}

	}, [user, pullLoginFromLocalstorage, history]);

	return (
		<>
			<section className="login">
				<div className="container login-container">
					<div className="row login-row">
						<div className="col-md-6 col-12 login-col">
							<img src={logo} className="logo" alt="logo" />
							<h1 className="login-header">Universal <span className="header-thin">| Check-in</span></h1>
							<AuthForm
								onSubmit={(username, password) => {
									logIn(username, password);
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export { LoginView };