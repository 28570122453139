import { Event } from '../models/Event';
import { AuthAction } from './Auth/AuthActions';

export type Action =
	| AuthAction
	| {type: 'RESET' }
	| {type: 'ERROR', payload: {message: string}}
	| {type: 'SELECT_EVENT', payload?: Event}
	| {type: 'FETCH_EVENTS'}
	| {type: 'FETCH_EVENTS_FAILED', payload: string}
	| {type: 'FETCH_EVENTS_SUCCESS', payload: Event[]}
	| {type: 'CHECK_IN_TICKET'}
	| {type: 'CHECK_IN_TICKET_FAILED', payload: string}
	| {type: 'CHECK_IN_TICKET_SUCCESS', payload: unknown}
	| {type: 'CHECK_IN_ORDER'}
	| {type: 'CHECK_IN_ORDER_FAILED', payload: string}
	| {type: 'CHECK_IN_ORDER_SUCCESS', payload: unknown}
	| {type: 'FETCH_ORDERS'}
	| {type: 'FETCH_ORDERS_FAILED', payload: string}
	| {type: 'FETCH_ORDERS_SUCCESS', payload: unknown[]}
	;

export const error = (error: {message: string}): Action => ({ type: 'ERROR', payload: error });
export const reset = (): Action => ({ type: 'RESET' });
export const selectEvent = (event?: Event): Action => ({ type: 'SELECT_EVENT', payload: event });

export const fetchEvents = (): Action => ({ type: 'FETCH_EVENTS' });
export const fetchEventsFailed = (message: string): Action => ({ type: 'FETCH_EVENTS_FAILED', payload: message });
export const fetchEventsSuccess = (events: Event[]): Action => ({ type: 'FETCH_EVENTS_SUCCESS', payload: events });

export const checkInOrderStarted = (): Action => ({ type: 'CHECK_IN_ORDER' });
export const checkInOrderFailed = (message: string): Action => ({ type: 'CHECK_IN_ORDER_FAILED', payload: message });
export const checkInOrderSuccess = (result: unknown): Action => ({ type: 'CHECK_IN_ORDER_SUCCESS', payload: result });

export const checkInTicketStarted = (): Action => ({ type: 'CHECK_IN_TICKET' });
export const checkInTicketFailed = (message: string): Action => ({ type: 'CHECK_IN_TICKET_FAILED', payload: message });
export const checkInTicketSuccess = (result: unknown): Action => ({ type: 'CHECK_IN_TICKET_SUCCESS', payload: result });

export const fetchOrdersStarted = (): Action => ({ type: 'FETCH_ORDERS' });
export const fetchOrdersFailed = (message: string): Action => ({ type: 'FETCH_ORDERS_FAILED', payload: message });
export const fetchOrdersSuccess = (orders: unknown[]): Action => ({ type: 'FETCH_ORDERS_SUCCESS', payload: orders });