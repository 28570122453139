import { Event } from '../models/Event';

export interface EventRowProps {
	event: Event,
	eventClicked: () => void
}

const EventRow = (props: EventRowProps) => {
	const {
		event,
		eventClicked,
	} = props;

	// Bug here, some reason it's displaying a day before the epoch? (0 offset?, i don't think so?)
	const eventEpoch = new Date(event.unixTimeOfEvent);

	const eventMonth = (eventEpoch.getMonth() + 1);
	const eventDay = Number(eventEpoch.toLocaleString('en-us', { day: '2-digit' }));
	const eventWeekday = eventEpoch.toLocaleString('en-us', { weekday: 'long' });
	const eventYear = eventEpoch.getFullYear();

	const getDate = () => {
		const thisMonth = eventEpoch.getMonth() === (new Date().getMonth() + 1);
		const thisYear = eventEpoch.getFullYear() === new Date().getFullYear();

		if(!thisYear){
			return `${eventMonth}/${eventDay}/${eventYear}`;
		}

		if(!thisMonth){
			return `${eventMonth}/${eventDay}`;
		}

		return eventDay;
	};

	return (
		<>
			<div className="row events-list-row" onClick={eventClicked}>
				<div className="col-12 events-list-col">
					<div className="calendar">
						<span className="date-name">{eventWeekday}</span>
						<span className="date-number">
							{getDate()}
						</span>
					</div>
					<div className="summary">
						<span className="title">
							{event.title}
						</span>
						<span className="location">
							{event.location}
						</span>
					</div>
					<span className="chevron">
						<i className="fa fa-chevron-right"></i>
					</span>
				</div>
			</div>
		</>
	);
};

export { EventRow };