import React, { createContext, useContext, useReducer } from 'react';
import { Action } from './Actions';
import { initalState, IState } from './IState';
import { AppReducer } from './Reducer';

const Store = createContext<{state: IState, dispatch: React.Dispatch<Action> }>(
	{
		state: initalState,
		dispatch: () => null,
	});
Store.displayName = 'Store';

export const useStore = () => useContext(Store);

export const StoreProvider = ({ children }: {children: React.ReactNode}) => {
	const [state, dispatch] = useReducer(AppReducer, initalState);

	return <Store.Provider value={{ state, dispatch }} >{children}</Store.Provider>;
};
