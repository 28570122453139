import { error, reset } from '../store/Actions';
import { useStore } from '../store/Store';
import { authFailed, authLogout, authStart, authSucces } from '../store/Auth/AuthActions';
import { BackendService } from '../services/BackendService';
import { useHistory } from 'react-router';

export const useAuthHooks = () => {

	const { dispatch } = useStore();
	const history = useHistory();

	const logIn = async (email: string, password: string) => {
		dispatch(authStart());
		const response = await BackendService.logIn(email, password);
		handleServiceResponse(response);
		const responseData = await response.json();

		if(!response.ok){
			dispatch(authFailed(responseData.error_description || 'Auth failed'));
			return;
		}

		if(!responseData.access_token || !responseData.userName){
			dispatch(authFailed('Login request missing data'));
		}

		dispatch(authSucces({
			accessToken: responseData.accessToken,
			username: responseData.username,
		}));
		history.push('/home');
	};

	const logOut = async () => {
		dispatch(authLogout());
		dispatch(reset());
	};

	const pullLoginFromLocalstorage = (): boolean => {
		const localstorageUser = window.localStorage.getItem('user');
		if(localstorageUser){
			dispatch(authSucces(JSON.parse(localstorageUser)));
			return true;
		}
		return false;
	};

	const handleServiceResponse = (response: Response): Response => {
		if(!response.ok){
			handleError({ message: `API Error(${response.status}): ${response.statusText}` });
		}

		return response;
	};

	const handleError = (errorPayload: {message: string}) => {
		dispatch(error(errorPayload));
	};

	return {
		logIn,
		logOut,
		pullLoginFromLocalstorage,
	};
};