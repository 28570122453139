import { useStore } from '../store/Store';
import { selectEvent as selectEventAction } from '../store/Actions';
import { Event } from '../models/Event';
import { useHistory } from 'react-router';

export const useApp = () => {

	const { dispatch } = useStore();
	const history = useHistory();

	const selectEvent = (event: Event) => {
		dispatch(selectEventAction(event));
		history.push('/event');
	};

	const goHome = () => {
		dispatch(selectEventAction());
		history.push('/');
	};

	const goToLogin = () => {
		history.push('/login');
	};

	return {
		selectEvent,
		goHome,
		goToLogin,
	};
};