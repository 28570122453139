import { Action } from './Actions';
import { initalState, IState } from './IState';
import { AuthReducer } from './Auth/AuthReducer';
import { AuthAction } from './Auth/AuthActions';

export const AppReducer = (state: IState, action: Action): IState => {

	if(action.type.includes('AUTH_')){
		// @TODO It's a bad idea to cast this here. Should fix this before commit
		return { ... state, auth: AuthReducer(state.auth, action as AuthAction) };
	}

	switch(action.type){

		case 'FETCH_EVENTS':
			return { ...state, loading: true, error: '' };

		case 'FETCH_EVENTS_FAILED':
			return { ...state, loading: false, error: action.payload };

		case 'FETCH_EVENTS_SUCCESS':
			return {
				...state,
				loading: false,
				error: '',
				data: {
					...state.data,
					events: action.payload,
				},
			};

		case 'CHECK_IN_TICKET':
			return {
				...state,
				loading: true,
				error: '',
			};

		case 'CHECK_IN_TICKET_FAILED':
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case 'CHECK_IN_TICKET_SUCCESS':
			return {
				...state,
				loading: false,
				error: '',
			};

		case 'CHECK_IN_ORDER':
			return {
				...state,
				loading: true,
				error: '',
			};

		case 'CHECK_IN_ORDER_FAILED':
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case 'CHECK_IN_ORDER_SUCCESS':
			return {
				...state,
				loading: false,
				error: '',
			};

		case 'FETCH_ORDERS':
			return {
				...state,
				data: {
					...state.data,
					orders: null,
				},
				loading: true,
				error: '',
			};

		case 'FETCH_ORDERS_FAILED':
			return { ...state, loading: false, error: action.payload };

		case 'FETCH_ORDERS_SUCCESS':
			console.log('orders', action.payload);
			return {
				...state,
				loading: false,
				error: '',
				data: {
					...state.data,
					orders: action.payload,
				},
			};

		case 'RESET':
			return initalState;

		case 'SELECT_EVENT':
			if(!action.payload){
				return {
					...state,
					error: '',
					data: {
						...state.data,
						selectedEvent: null,
						orders: null,
					},
				};
			}
			return {
				...state,
				data: {
					...state.data,
					selectedEvent: action.payload,
				},
			};

		default:
			return state;
	}
};