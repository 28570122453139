import { useEffect } from 'react';
import { useDate } from '../../hooks/useDate';
import { useTicketSocket } from '../../hooks/useTicketSocket';
import { useStore } from '../../store/Store';
import { EventsSecion } from '../EventsSection';

const HomeView = () => {
	const { state } = useStore();
	const { getEvents } = useTicketSocket();
	const { isEventToday } = useDate();
	const showAllEvents = true;

	useEffect(() => {
		if(!state.data.events && !state.loading){
			getEvents();
		}
	}, [getEvents, state]);

	const renderContent = () => {

		if(state.data.events?.length){

			if(showAllEvents){
				return (
					<EventsSecion
						sectionTitle='All Events'
						events={state.data.events}
					/>
				);
			}

			const todaysEvents = state.data.events.filter(event => isEventToday(event));
			if(todaysEvents.length){
				return (
					<EventsSecion
						sectionTitle='Today!'
						events={todaysEvents}
					/>
				);
			}
		}

		if(state.loading){
			return <p className="events-user-message">Loading events.</p>;
		}

		if(!state.loading && state.error){
			return <p className="events-user-message">Error: "{state.error}"</p>;
		}

		return <p className="events-user-message">No events today.</p>;
	};

	return (
		<>

			<section className="events">
				<p className="events-user-message">Welcome home {state.auth.user?.username}!</p>
				<div className="container events-container">
					{renderContent()}
				</div>
			</section>
		</>
	);
};

export { HomeView };