import cardicon from '../images/card-icon.svg';

interface StartCheckinButtonProps {

	startClicked?: () => void
}

const StartCheckinButton = (props: StartCheckinButtonProps) => {

	const {
		startClicked = () => null,
	} = props;

	return (
		<section className="check-in">
			<div className="container check-in-container">
				<div className="row check-in-row">
					<div className="col-12 check-in-col">
						<span className="divider">
						</span>
						<div className="card">
							<img src={cardicon} className="icon-card" alt="Check-in Icon" />
							<button className="btn btn-card" onClick={startClicked}>Scan QR Code</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export { StartCheckinButton };