import { User } from '../models/User';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';

export const BackendService = {

	getAuthHeaders: (userToken?: string): HeadersInit | null => {
		if(userToken){
			return {
				Authorization: `Bearer ${userToken}`,
			};
		}

		return null;
	},

	logIn: async (userName: string, password: string): Promise<Response> => (
		await fetch(`${BACKEND_URL}auth`, {
			method: 'POST',
			body: JSON.stringify({
				userName,
				password,
			}),
		})
	),

	getEvents: async (user: User): Promise<Response | null> => {
		const headers = BackendService.getAuthHeaders(user.accessToken);
		if(!headers){
			return null;
		}

		return await fetch(`${BACKEND_URL}getEvents`, {
			headers,
			method: 'GET',
		});

	},

	checkInTicket: async (user: User, eventId: string, barcode: string): Promise<Response | null> => {
		const headers = BackendService.getAuthHeaders(user.accessToken);
		if(!headers){
			return null;
		}

		return (
			await fetch(`${BACKEND_URL}checkInTicket`, {
				headers,
				method: 'POST',
				body: JSON.stringify({
					eventId,
					barcode,
				}),
			})
		);
	},

	fetchOrders: async (user: User, eventId?: string): Promise<Response | null> => {
		const headers = BackendService.getAuthHeaders(user.accessToken);
		if(!headers){
			return null;
		}

		return (
			await fetch(`${BACKEND_URL}getOrders`, {
				headers,
				method: 'POST',
				body: JSON.stringify({
					eventId,
				}),
			})
		);
	},

};