import { useApp } from '../hooks/useApp';
import { Event } from '../models/Event';
import { EventRow } from './EventRow';

export interface EventsSectionProps {
	sectionTitle: string,
	events: Event[],
}

const EventsSecion = (props: EventsSectionProps) => {
	const {
		sectionTitle,
		events,
	} = props;

	const { selectEvent } = useApp();

	const renderEvents = (events: Event[]) => events.map((event) => (
		<EventRow
			key={event.id}
			event={event}
			eventClicked={() => {selectEvent(event);}}
		/>
	));

	if(!events.length){
		return <></>;
	}

	return (
		<>
			<div className="row events-header-row">
				<div className="col-md-6 col-12 events-header-col">
					<span className="events-header">{sectionTitle}</span>
				</div>
			</div>
			{renderEvents(events)}
		</>
	);
};

export { EventsSecion };