import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import { LoginView } from './views/LoginView';
import { PrivateRoute } from './PrivateRoute';
import { HomeView } from './views/HomeView';
import { SingleEventView } from './views/SingleEventView';
import { Nav } from './Nav';
import { useStore } from '../store/Store';

const AppRouter = () => {
	const { state } = useStore();
	return (
		<>
			<Nav />
			<Router>
				<Switch>
					<Route path="/login">
						<LoginView/>
					</Route>
					<Route path="/event">
						<SingleEventView event={state.data.selectedEvent}/>
					</Route>
					<PrivateRoute path="/">
						<HomeView />
					</PrivateRoute>
				</Switch>
			</Router>
		</>
	);
};
export { AppRouter };