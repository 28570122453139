import { ContinuousQrScanner } from 'react-webcam-qr-scanner.ts';

interface QrScannerProps {

	scannedCode?: (code: string) => void
	cancelClicked?: () => void
}

const QrScanner = (props: QrScannerProps) => {
	const {
		scannedCode = () => null,
		cancelClicked = () => null,
	} = props;

	const scanned = (code: string) => {
		console.log('scanned', code);
		scannedCode(code);
	};

	return (
		<section className="check-in">
			<div className="container check-in-container">
				<div className="row check-in-row">
					<div className="col-12 check-in-col">
						<span className="divider">
						</span>
						<div className="card" style={{ height: 'auto' }}>
							<ContinuousQrScanner
								onQrCode={(code) => {scanned(code);}}
								hidden={false}
							/>
							<button className="btn btn-card cancel" onClick={cancelClicked}>Cancel check-in</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export { QrScanner };