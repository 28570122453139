import { Route } from 'react-router';
import { useApp } from '../hooks/useApp';
import { useStore } from '../store/Store';

export interface PrivateRouteProps {
	path: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {

	const {
		children,
		path,
	} = props;

	const {
		state: {
			auth: {
				user,
			},
		},
	} = useStore();

	const { goToLogin } = useApp();

	if(!user){
		goToLogin();
	}

	return (
		<Route path={path}>
			{children}
		</Route>
	);
};

export { PrivateRoute };