import { User } from '../../models/User';

export type AuthAction =
	| {type: 'AUTH_LOGOUT'}
	| {type: 'AUTH_FAILED', payload: string}
	| {type: 'AUTH_SUCCESS', payload: User}
	| {type: 'AUTH_START'}


const authLogout = ():AuthAction => (
	{ type: 'AUTH_LOGOUT' }
);

const authFailed = (message: string): AuthAction => (
	{ type: 'AUTH_FAILED', payload: message }
);

const authSucces = (user: User): AuthAction => (
	{ type: 'AUTH_SUCCESS', payload: user }
);

const authStart = (): AuthAction => (
	{ type: 'AUTH_START' }
);

export {
	authLogout,
	authFailed,
	authSucces,
	authStart,
};