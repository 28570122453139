import { Event } from '../models/Event';
import { BackendService } from '../services/BackendService';
import { checkInTicketFailed, checkInTicketStarted, checkInTicketSuccess, fetchEvents, fetchEventsFailed, fetchEventsSuccess, fetchOrdersFailed, fetchOrdersStarted, fetchOrdersSuccess } from '../store/Actions';
import { useStore } from '../store/Store';

interface IEventsResponse {
	title?: string;
	start?: string;
	venue?: string;
	id?: string;
}

export const useTicketSocket = () => {

	const {
		state,
		dispatch,
	} = useStore();

	const getEvents = async () => {
		dispatch(fetchEvents());
		if(!state.auth.user){
			dispatch(fetchEventsFailed('fetch failed: no user'));
			return;
		}
		const eventsResponse = await BackendService.getEvents(state.auth.user);

		if(!eventsResponse || !eventsResponse.ok){
			dispatch(fetchEventsFailed(`fetch failed: ${eventsResponse?.statusText || 'not authed?'}`));
			return;
		}

		const serviceEventData: IEventsResponse[] = await eventsResponse.json();
		const events: Event[] = serviceEventData
			.map(e => ({
				title: e.title,
				unixTimeOfEvent: new Date(Number.parseInt(e.start + '000')).getTime(),
				location: e.venue,
				id: e.id,
			} as Event));

		dispatch(fetchEventsSuccess(events));
	};

	const checkInTicket = async (eventId: string, barCode: string) => {
		dispatch(checkInTicketStarted());

		if(!state.auth.user){
			dispatch(checkInTicketFailed('fetch failed: no user'));
			return;
		}

		const checkInResponse = await BackendService.checkInTicket(state.auth.user, eventId, barCode);
		if(!checkInResponse){
			dispatch(checkInTicketFailed(`fetch failed: ${'user not logged in?'}`));
			return;
		}

		const checkInResponseBody = await checkInResponse.json();
		if(!checkInResponseBody || !checkInResponseBody.valid){
			dispatch(checkInTicketFailed(convertErrorMessageToUserMessage(checkInResponseBody)));
			return;
		}

		dispatch(checkInTicketSuccess(checkInResponseBody));
	};

	const fetchOrders = async (eventId?: string) => {
		dispatch(fetchOrdersStarted());

		if(!state.auth.user){
			dispatch(fetchOrdersFailed('fetch failed: no user'));
			return;
		}

		const checkInResponse = await BackendService.fetchOrders(state.auth.user, eventId);

		if(!checkInResponse || !checkInResponse.ok){
			dispatch(fetchOrdersFailed(`fetch failed: ${checkInResponse?.statusText || 'user not logged in?'}`));
			return;
		}

		const checkInResponseBody = await checkInResponse.json();
		dispatch(fetchOrdersSuccess(checkInResponseBody.items));
	};

	const convertErrorMessageToUserMessage = (responseBody: {valid?: boolean, reason?: string, anotherEvent?: boolean, anotherTimeSlot?: boolean}) => {
		if(responseBody.valid){
			return '';
		}

		if(responseBody.reason){
			return responseBody.reason;
		}

		if(responseBody.anotherEvent){
			return 'Wrong event!';
		}

		if(responseBody.anotherTimeSlot){
			return 'Wrong time slot!';
		}

		return 'Unknown error!';
	};

	return {
		getEvents,
		checkInTicket,
		fetchOrders,
	};
};