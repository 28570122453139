// for nav ribbon
import tslogo from '../images/logo.png';
import { useAuthHooks } from '../hooks/useAuth';

const Nav = () => {

	const { logOut } = useAuthHooks();

	if(window.location.pathname === '/login'){
		return <></>;
	}

	return (
		<nav className="navbar navbar-fiba">
			<a className="navbar-brand navbar-fiba-logo" href="#"><img src={tslogo} className="logo" alt="logo" /></a><button className="btn btn-logout" onClick={logOut}> Logout</button>
		</nav>
	);
};

export { Nav };