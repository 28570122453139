import { useApp } from '../hooks/useApp';
import { useStore } from '../store/Store';

export enum CHECKIN_STATUS {
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface CheckInStatusProps {
	state?: CHECKIN_STATUS

	startAnotherScan?: () => void
}

const CheckInStatus = (props: CheckInStatusProps) => {
	const {
		state = CHECKIN_STATUS.LOADING,

		startAnotherScan = () => null,
	} = props;

	const { state: appState } = useStore();
	const { goHome } = useApp();

	const renderIcon = () => {
		switch(state){
			case CHECKIN_STATUS.SUCCESS:
				return <i className="fa fa-check-circle success-icon"></i>;
			case CHECKIN_STATUS.ERROR:
				return <i className="fa fa-times-circle success-icon-error" aria-hidden="true"></i>;
			case CHECKIN_STATUS.LOADING:
				return <i className="fa fa-spinner fa-spin success-icon" aria-hidden="true"></i>;
		}
	};

	const getHeader = () => {
		switch(state){
			case CHECKIN_STATUS.SUCCESS:
				return 'Checkin success';
			case CHECKIN_STATUS.ERROR:
				return 'Checkin error';
			case CHECKIN_STATUS.LOADING:
				return 'Checking in, one sec.';
		}
	};

	const getSubHeader = () => {
		switch(state){
			case CHECKIN_STATUS.SUCCESS:
				return 'Nice job!';
			case CHECKIN_STATUS.ERROR:
				return appState.error || 'Unknown error, sorry.';
			case CHECKIN_STATUS.LOADING:
				return '';
		}
	};


	return (
		<>
			<section className="check-in">
				<div className="container check-in-container">
					<div className="row check-in-row">
						<div className="col-12 check-in-col">
							<span className="divider">
							</span>
							<div className="success">
								{renderIcon()}
								<h2 className="success-header">{getHeader()}</h2>
								<span className="success-sub-copy">{getSubHeader()}</span>
							</div>
							<span className="divider divider-bottom">
							</span>
							<button className="btn btn-continue" onClick={startAnotherScan}>Continue scanning</button>
							<button className="btn btn-destroy" onClick={goHome}>Close scanner</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export { CheckInStatus };