import { User } from '../../models/User';


export interface IAuthState {
	user: User | null;
	error: string | null;
	pending: boolean;
}

const initalAuthState: IAuthState = {
	user: null,
	error: null,
	pending: false,
};

export{
	initalAuthState,
};