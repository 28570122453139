import { ChangeEvent, useState } from 'react';
import { useStore } from '../store/Store';

export interface AuthFormProps {
	buttonText?: string,
	disabled?: boolean,
	onSubmit?: (username: string, password: string) => void,
}

const AuthForm = (props: AuthFormProps) => {

	const {
		buttonText = 'Log In',
		disabled,

		onSubmit,
	} = props;

	const {
		state: {
			auth: {
				error,
				pending,
			},
		},
	} = useStore();

	const [password, setPassword] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const formDisabled = disabled || pending;

	const formSubmitted = (event: ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		if(!onSubmit){
			console.warn('DEVELOPER! this login form doesn\'t do anything on submit!');
			return;
		}

		onSubmit(email, password);
	};

	const renderStateFeedback = () => {
		if(pending){
			return (
				<>
					<p>Loading...</p>
				</>
			);
		}
		if(error && !pending){
			return (
				<>
					<p>ERROR: {error}</p>
				</>
			);
		}
	};

	return (
		<>
			{renderStateFeedback()}
			<form className="login-form" onSubmit={formSubmitted}>
				<div className="form-group">
					<input
						type="text"
						className="form-control login-form-input"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						placeholder="Username"
						onChange={(event) => {
							setEmail(event.currentTarget.value);
						}}
						disabled={formDisabled}
					/>
				</div>
				<div className="form-group">
					<input
						type="password"
						className="form-control login-form-input"
						id="exampleInputPassword1"
						placeholder="Password"
						onChange={(event) => {
							setPassword(event.currentTarget.value);
						}}
						disabled={formDisabled}
					/>
				</div>
				<button type="submit" className="btn btn-submit" disabled={formDisabled || !email || !password}>
					{buttonText}
				</button>
			</form>
		</>
	);
};

export { AuthForm };