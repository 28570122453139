import { AuthAction } from './AuthActions';
import { IAuthState } from './IAuthState';

export const AuthReducer = (state: IAuthState, action: AuthAction): IAuthState => {
	switch(action.type){

		case 'AUTH_LOGOUT':
			window.localStorage.removeItem('user');
			return { ...state, user: null };

		case 'AUTH_START':
			return { ...state, pending: true, error: '' };

		case 'AUTH_FAILED':
			return 	{ ...state, pending: false, error: action.payload || 'AUTH_FAILED' };

		case 'AUTH_SUCCESS':
			window.localStorage.setItem('user', JSON.stringify(action.payload));
			return { ...state, user: action.payload, pending: false, error: '' };

		default:
			return state;
	}
};